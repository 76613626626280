import React, { useState } from "react";
import { createContext } from "react";

export const ModeContext = createContext();

const ModeContextProvider = ({children}) => {
  const [mode, setMode] = useState(localStorage.getItem("mode") || "light");
  const setColorMode = () => {
    setMode(mode === "dark" ? "light" : "dark");
    localStorage.setItem("colorMode", mode === "dark" ? "light" : "dark");
  };
  return (
    <ModeContext.Provider value={{ mode, setColorMode }}>
      {children}
    </ModeContext.Provider>
  );
};

export default ModeContextProvider;
