import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { loadState } from "../../utils/load-state";
import request from "../../server/request";

const initialState = {
    msg: '',
    user: '',
    token: localStorage.getItem('token') || null,
    loading: false,
    error: ''
}

// register
export const signUpUser = createAsyncThunk('signupuser', async (body) => {
    console.log(body);
    const res = await request.post(`signup`, body);
    console.log(res);
    return res
})

// Login
export const signInUser = createAsyncThunk('signinuser', async (body) => {
    const { data } = await request.post(`login`, body)
    return data;
})

export const authSlice = createSlice({

    name: 'user',
    initialState,
    reducers: {
        addToken: (state) => {
            state.token = localStorage.getItem("token")
        },
        addUser: (state) => {
            state.user = JSON.parse(localStorage.getItem("user"))
        },
        logout: (state) => {
            state.token = null;
            localStorage.removeItem('token');
        }
    },

    extraReducers: {
        // Login
        [signInUser.pending]: (state) => {
            state.loading = true
        },
        [signInUser.fulfilled]: (state, { payload: { data } }) => {
            state.loading = false
            state.token = data?.access_token
            localStorage.setItem('token', data?.access_token);
            
        },
        [signInUser.rejected]: (state) => {
            state.loading = false
        },

        // register
        [signUpUser.pending]: (state, action) => {
            state.msg = 'pending'
            state.loading = true
        },
        [signUpUser.fulfilled]: (state, { payload: { data, error } }) => {
            if (!error) {
                state.msg = 'Success'
                state.loading = true
                state.token = data?.data.access_token
                localStorage.setItem('token', state.token)
            } else {
                state.error = error
            }
        },
        [signUpUser.rejected]: (state) => {
            state.loading = true
        }
    }
})

export const { addToken, addUser, logout } = authSlice.actions;
export default authSlice.reducer;
