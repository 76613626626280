import React, { lazy, Suspense } from "react";
import Layout from "../components/Layout/Layout";
import Spinner from "../components/Spinner";
import ScrollTop from "../hoc/ScrollTop";
import { Route, Routes } from "react-router";
import { useSelector } from "react-redux";

// profile
import UserLayout from "../components/Layout/admin/User/userLayout";
import { UserProfile } from "../page/profile/user/user-profile";
import { UserAppeals } from "../page/profile/user/user-appeals";
import { UserChat } from "../page/profile/user/user-chat";

// web site pages
const Home = lazy(() => import("../page/Home/Home"));
const TrainingCenter = lazy(() => import("../page/TrainingCenter/trainingCenter"));
const TrainingTeacher = lazy(() =>import("../page/TrainingTeacher/TrainingTeacher"));
const TeacherPage = lazy(() =>import("../components/TeacherPage/TeacherPage"));
const CenterPage = lazy(() =>import("../components/CenterPage/CenterPage"));
const About = lazy(() => import("../page/About/About"));
const Contact = lazy(() => import("../page/Contact/Contact"));
const Login = lazy(() => import("../page/Login/Login"));
const Register = lazy(() => import("../page/Register/Register"));
const NotFound = lazy(() => import("../page/404"));

// web site single pages

// web site page and single page routes
const routes = [
    { path: "", element: Home },

    // { path: "admin/learnCenter", element: adminLearnCenter },
    // { path: "admin/teacher", element: adminTeacher },
    { path: "trainingTeacher", element: TrainingTeacher },
    { path: "trainingCenter", element: TrainingCenter },
    { path: "trainingCenter/:id", element: CenterPage },
    { path: "trainingTeacher/:id", element: TeacherPage },
    { path: "about", element: About },

    { path: "trainingTeacher/*", element: TrainingTeacher },
    { path: "teacher/:id", element: TeacherPage },
    { path: "trainingCenter/*", element: TrainingCenter },
    { path: "center/:id", element: CenterPage },

    { path: "contact", element: Contact },
    { path: "about", element: About },
    { path: "signin", element: Login },
    { path: "signup", element: Register },
];

// profile page routes
const profile = [
    { path: "", element: UserProfile },
    { path: "murojatlarim", element: UserAppeals },
    { path: "chat", element: UserChat }
]

export const Router = () => {
    const { token } = useSelector((stete) => stete.user);
    const roleId = token && JSON.parse(atob(token.split('.')[1]));
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {routes.map((route, key) => {
                    const RouteComponent = ScrollTop(route.element);
                    return (
                        <Route key={key} index={!route.path && true} path={route.path} element={
                            <Suspense fallback={<Spinner position="full" />}>
                                <RouteComponent />
                            </Suspense>
                        }
                        />
                    );
                })}
            <Route path="*" element={<NotFound />} />
            </Route>
            {roleId &&
            <Route path="/profile" element={<UserLayout />} >
                {profile.map((item, index) => {
                    const RouteComponent = ScrollTop(item.element);
                    return <Route key={index} index={!item.path && true} path={item.path} element={<RouteComponent />}/>
                })}
            </Route>
            }
        </Routes>
    )
}