import axios from "axios";

const request = axios.create({
    baseURL: 'http://meelon.uz/',
})

request.interceptors.response.use(
(request) => request, (error) => {
    if (error.response.status === 401) {
        console.log('auth');
    } else if (error.response.states === 500) {
        console.log('server');
    }else {
        console.log(error.response.data.message);
    }
    return Promise.reject(error) 
})

export default request