import React, { useState } from "react";
import { Layout, Menu, Switch } from "antd";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { UserOutlined, MessageOutlined, MailOutlined } from "@ant-design/icons";
import Logo from "../../../../assets/images/l.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/Auth/authSlice";

const { Header, Content, Sider } = Layout;

const UserLayout = () => {
  const [themes, setTheme] = useState("light");
  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Layout>
      <Sider
        style={{
          background: "white",
          padding: "0 24px",
          paddingBottom: "32px",
        }}
      >
        <div
          className="side"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "95vh",
          }}
        >
          <div>
            <img
              className="learnCenter_logos "
              src={Logo}
              alt="site logo"
              style={{ padding: "36px 0" }}
            />
            <hr style={{ backgroundColor: "#F1F1F4" }} />
            <span
              style={{
                margin: "20px 0",
                display: "inline-block",
                fontSize: "10px",
                textTransform: "uppercase",
              }}
            >
              user panel
            </span>
            <Menu defaultSelectedKeys={["1"]}>
              <Menu.Item key="/profile" style={{ paddingLeft: "10px" }}>
                <NavLink to="/profile">
                  <UserOutlined /> Profile
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="/profile/murojatlarim"
                style={{ paddingLeft: "10px" }}
              >
                <NavLink to="/profile/murojatlarim">
                  <MailOutlined /> Murojatlarim
                </NavLink>
              </Menu.Item>
              <Menu.Item key="/profile/chat" style={{ paddingLeft: "10px" }}>
                <NavLink to="/profile/chat">
                  <MessageOutlined /> Chat
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>

          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "25px",
              }}
            >
              <p>{themes === "light" ? "Qorong'i rejim" : "Yo'rug' rejim"}</p>
              <Switch onChange={changeTheme} /> <br />
            </div>
            <p
              onClick={() => {
                dispatch(logout({}));
                navigate("/");
              }}
            >
              Chiqish
            </p>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            background: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "36px 23px",
          }}
        >
          <div>
            <p
              style={{
                margin: 0,
                padding: "0 20px",
              }}
            >
              Bosh sahifa
            </p>
          </div>
        </Header>
        <Content style={{ margin: "24px", borderRadius: "14px" }}>
          <div style={{ minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserLayout;
