import React, { useState } from "react";
import { createContext } from "react";

export const BlackContext = createContext();

const BlackContextProvider = ({ children }) => {
    const [isGray, setGray] = useState(
        localStorage.getItem("grayMode") ? localStorage.getItem("blackMode") : false
    );

    const grayScale = () => {
        setGray(!isGray);
        localStorage.setItem("grayMode", !isGray);
    };

    const htmlTag = document.getElementsByTagName("html");

    JSON.parse(isGray)
        ? htmlTag[0].classList.add("black-scale")
        : htmlTag[0].classList.remove("black-scale");

    return (
        <BlackContext.Provider value={{ isGray, grayScale }}>
            {children}
        </BlackContext.Provider>
    );
};

export default BlackContextProvider;
