import React from 'react';
import { Form, Input } from 'antd';
import "./style.css";

export const UserProfile = () => {
  return (
    <div style={{padding: '16px 60px', backgroundColor: '#fff', borderRadius: '12px'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '62px', alignItems: 'center' }}>
        <h2>Profile</h2>
        <button className='profile-btn'>O'zgartirish</button>
      </div>
      <Form name="wrap"
        labelCol={{
          flex: '110px',
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{
          flex: 1,
        }}
        colon={false}
        style={{display: 'flex', justifyContent: 'space-between'}}>

        <div style={{width: '289px'}}>
          <Form.Item label="Telefon raqam" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="Ism familiyasi" name="username">
            <Input />
          </Form.Item>
          <Form.Item label="Elektron pochta" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="O’quv markazi yoki domla ismi" name="name">
            <Input />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['user', 'introduction']} label="Sharoyiti">
            <Input.TextArea  style={{minWidth: '626px', minHeight: '278px'}} placeholder="Sharoyiti juda yaxshi"/>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
