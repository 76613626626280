import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router/routes";

export const RoutesContainer = () => {
    return (
        <BrowserRouter>
            <Router/>
        </BrowserRouter>
    )
};
