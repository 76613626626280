import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../../../assets/images/icon/logo.svg";
import LogoDark from "../../../assets/images/icons/logo-dark.svg";
import { BsSun } from "react-icons/bs";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { ModeContext } from "../../context/ModeContext";
import { useContext } from "react";
function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const {mode,setColorMode} = useContext(ModeContext);
  let html = document.querySelector("html");
  html.setAttribute("data-theme", mode == "light" ? "dark" : "light");
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }
      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);
  const { token } = useSelector((stete) => stete.user);
  const roleId = token && JSON.parse(atob(token.split(".")[1]));
  const handleColorMode = () => {
    localStorage.setItem("colorMode", mode == "light" ? "dark" : "light");
    setColorMode(mode == "light" ? "dark" : "light");
  };
  return (
    <header className="header ">
      <div className={`container`}>
        <div className={`header_navbar ${!show && "shrink"}`}>
          <Link to="/">
            <img src={mode == "light" ? LogoDark : Logo} alt="logo" />
          </Link>
          <div className={`header_navbar_close ${menuOpen ? "open" : ""}`}>
            <ul className="header_navbar_close_left">
              <li className="header_navbar_close_left_item">
                <Link to="/">Bosh sahifa</Link>
              </li>
              <li className="header_navbar_close_left_item">
                <Link to="/trainingCenter">O'quv markazlari</Link>
              </li>
              <li className="header_navbar_close_left_item">
                <Link to="/trainingTeacher">Ustozlar</Link>
              </li>
              <li className="header_navbar_close_left_item">
                <Link to="/contact">Kontaktlar</Link>
              </li>
              <li className="header_navbar_close_left_item">
                <Link to="/about">Biz haqimizda</Link>
              </li>
            </ul>
            <div className="header_navbar_close_right">
              <div onClick={() => handleColorMode()}>
                <BsSun className="header_navbar_close_right_sun" />
              </div>

              {roleId ? (
                <div
                  style={{
                    backgroundColor: "#EEE",
                    padding: "14px",
                    borderRadius: "10px",
                  }}
                >
                  <Link to="/profile" style={{ color: "#000" }}>
                    <UserOutlined /> Profile
                  </Link>
                </div>
              ) : (
                <>
                  <button className="header_navbar_close_right_signUp">
                    <Link to={"/signin"}>Kirish</Link>
                  </button>
                  <button className="header_navbar_close_right_register">
                    <Link to={"/signup"}>Roʻyxatdan oʻtish</Link>
                  </button>
                </>
              )}
            </div>
          </div>
          <button className="burger" onClick={() => setMenuOpen(!menuOpen)}>
            {" "}
            {!menuOpen ? (
              <AiOutlineMenu className="header_icon" />
            ) : (
              <AiOutlineClose className="header_icon" />
            )}
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
