import React from 'react';
import ReactDOM from 'react-dom/client';
import "../src/assets/style/global.scss";
import "../src/assets/style/Header.scss";
import "../src/assets/style/Footer.scss";   
import {RoutesContainer} from "./RoutesContainer";
import Provider from "react-redux/es/components/Provider";
import {store} from "./redux/Auth/store";
import BlackContextProvider from "./components/context/BlackContext";
import ModeContextProvider from './components/context/ModeContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ModeContextProvider>
        <BlackContextProvider>
            <Provider store={store}>
                <RoutesContainer/>
            </Provider>
        </BlackContextProvider>
        </ModeContextProvider>
    // </React.StrictMode>
)
