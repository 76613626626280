import React from 'react'
import { Table } from 'antd';
import "./style.css";

const { Column, ColumnGroup } = Table;
const data = [
  {
    key: '01',
    id: '#345782',
    name: 'Muallim',
    date: '21/05/2023 09:31',
    active: 'Tasdiqlangan'
  },
  {
    key: '02',
    id: '#897632',
    name: 'Muallim',
    date: '21/05/2023 09:31',
    active: "ko'rilmoqda"
  },
];
export const UserAppeals = () => (
  <div className='use-appeals'>
    <h2>Murojatlarim</h2>
    <Table className="ant-table-thead" dataSource={data}>
      <ColumnGroup>
        <Column title="#" dataIndex="key" key="key"/>
        <Column title="id" dataIndex="id" key="id" />
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Murojat sanasi" dataIndex="date" key="date" />
        <Column title="Holat" dataIndex='active' key="address" />
      </ColumnGroup>
    </Table>
  </div>
);