import React from 'react'
import "./style.css";

export const UserChat = () => {
  return (
    <div className='user-chat'>
      <h2>Melon Admin</h2>
    </div>
  )
}

