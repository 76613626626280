import React from "react";
import Logo from "../../../assets/images/footer-logo.svg";
import LogoDark from "../../../assets/images/icons/logo-dark.svg";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { CiLocationOn, CiInstagram } from "react-icons/ci";
import { PiTelegramLogoLight } from "react-icons/pi";
import { FaFacebookF } from "react-icons/fa";
import { useContext } from "react";
import { ModeContext } from "../../context/ModeContext";
function Footer() {
  let { mode } = useContext(ModeContext);
  return (
    <div className="footer">
      <div className="footer_top container">
        <div className="footer_top_cards">
          <img src={mode === "light" ? LogoDark : Logo} alt="logo" />
          <div className="footer_top_cards_title">Biz bilan bog'laning</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <HiOutlineMail className="footer__icon" />
            <div className="footer_top_cards_text">info@melon.uz</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <FiPhoneCall className="footer__icon" />
            <div className="footer_top_cards_text">998 (90) 109 24 35</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <CiLocationOn
              className="footer__icon"
              style={{ fontSize: "30px" }}
            />
            <div className="footer_top_cards_text">
              634034, Toshkent sh., Naximovna ko‘chasi, 8/2
            </div>
          </div>
        </div>
        <div className="footer_top_cards">
          <div className="footer_top_cards_info">Ma’lumot</div>
          <div className="footer_top_cards_text">Xizmatlar</div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Biz haqimizda</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Hamkorlar</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Kontaktlar</div>
          </div>
        </div>
        <div className="footer_top_cards">
          <div className="footer_top_cards_info">Ijtimoiy</div>
          <div className="footer_top_cards_text">
            <a href="https://t.me/melonmagic28">Telegram</a>
          </div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">
              <a href="https://www.instagram.com/melonmagic28">Instagram</a>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Facebook</div>
          </div>
        </div>
        <div className="footer_top_cards">
          <div className="footer_top_cards_info">Huquqiy</div>
          <div className="footer_top_cards_text">Shartlar</div>

          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Maxfiylik</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Cookie-fayllar</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Litsenziyalar</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Sozlamalar</div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div className="footer_top_cards_text">Aloqa</div>
          </div>
        </div>
      </div>
      <div className="footer_end container">
        <div className="footer_end_year">
          © 2023 Melon. Barcha huquqlar himoyalangan.
        </div>
        <div className="footer_end_social">
          <a href="https://t.me/melonmagic28">
            <PiTelegramLogoLight className="footer__icon" />
          </a>
          <a href="https://www.instagram.com/melonmagic28">
            <CiInstagram className="footer__icon" />
          </a>
          <a href="#">
            <FaFacebookF className="footer__icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
